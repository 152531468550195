<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">
            Billing Console Home - Welcome, {{ $auth.isAuthenticated ? $auth.user.name  + '!': 'Please sign in to continue'}}
          </h1>
        </div>
      </div>
    </div>
    <div v-if="$auth.isAuthenticated">
      <v-item-group
        v-for="section in filteredLinksArray"
        v-bind:key="section.id"
        cols="12"
        md="3"
      >
        <v-container class="pb-0"
          v-if="pageAccess(section.role)"
        >
          <h1 class="is-size-4 mb-2">{{ section.title }}</h1>
          <v-row>
            <v-col
              v-for="page in section.links"
              v-bind:key="page.id"
              cols="12"
              md="3"
              class="homeLink"
            >
              <v-item v-if="pageAccess(page.role)">
                <router-link :to="page.link">
                  <v-card class="d-flex align-center" height="90">
                    <p class="title center">{{ page.title }}</p>
                  </v-card>
                </router-link>
              </v-item>
            </v-col>
    
          </v-row>
          <v-divider class="mb-0"/>
        </v-container>
       
      </v-item-group>
      <footer
        class="hero is-primary pt-4 pb-1 mt-4"
        footer-background-color="red"
      >
        <div class="content has-text-centered hero-body">
          <p>3PL Billing Console</p>
        </div>
      </footer>
    </div>
    <!-- <EventsList /> -->
  </div>
</template>
<script>
// import EventsList from '../components/EventsList';
import UserRoles from "@/mixins/UserRoles";
export default {
  mixins: [UserRoles],
  data() {
    return {
      linksArray: [
        {
          title: "Carriers",
          role: 'viewOnly',
          links: [
            { link: "/carriers", title: "Carriers", role: 'admin' },
            { link: "/services", title: "Services", role: 'admin'},
            {
              link: "/margins",
              title: "Company Margins",
              role: 'viewOnly'
            },
            {
              link: "/carriers/invoice-reconciliation",
              title: "Invoice Reconciliation",
              role: 'admin'
            },
          ],
        },
        {
          title: "Snap",
          role: 'viewOnly',
          links: [
            {
              link: "/billing-snap",
              title: "View Snap Data",
              role: 'admin',
            },
            {
              link: "/edit-snap-billing-rates",
              title: "Company Billing Rates",
              role: 'viewOnly',
            },
            {
              link: "/billing-rates/bulk-update-company-rates",
              title: "Bulk Update Company Rates",
              role: 'admin',
            },
            {
              link: "/add-edit-snap-rates",
              title: "Add/Edit Consumables/Storage Sizes",
              role: 'admin',
            },
            {
              link: "/generated-billing-query",
              title: "Generated Billing Query",
              role: 'dev',
            },
          ],
        },
        {
          title: "Sorted",
          role: 'admin',
          links: [
            {
              link: "/billing-upload",
              title: "Upload Sorted Data",
              role: 'admin',
            },
            {
              link: "/edit-sorted-data",
              title: "Edit Sorted Data",
              role: 'admin',
            },
            {
              link: "/sorted-billing-import-errors",
              title: "Automated Sorted Billing Import Errors",
              role: 'admin',
            },
          ],
        },
        {
          title: "Invoicing",
          role: 'admin',
          links: [
            {
              link: "/adhoc-charges",
              title: "Ad-Hoc Charges",
              role: 'admin',
            },
             {
              link: "/search-adhoc-charges",
              title: "Search Ad-Hoc Charges",
              role: 'admin',
            },
            {
              link: "/create-invoice",
              title: "Create Invoice Data",
              role: 'admin',
            },
            {
              link: "/download-invoice",
              title: "Download Invoice Data",
              role: 'admin',
            },
            {
              link: "/create-invoice-prep-companies",
              title: "Create Invoice Prep Companies",
              role: 'admin',
            },
            {
              link: "/download-invoice-prep-companies",
              title: "Download Invoice Prep Companies",
              role: 'admin',
            },
            {
              link: "/delete-uncommitted-billing-data",
              title: "Delete Uncommitted Billing Data",
              role: 'admin',
            },
            {
              link: "/billing-charges-by-order-number",
              title: "Billing Charges By Order Number",
              role: 'admin',
            },
            {
              link: "/invoicing/xero-invoice-emails",
              title: "Xero Invoice Emails",
              role: 'admin',
            },
            {
              link: "/invoicing/company-billing-settings",
              title: "Company Billing Settings",
              role: 'admin',
            },
          ],
        },
        {
          title: "Other",
          role: 'admin',
          links: [
            {
              link: "/inbound-bookings",
              title: "Inbound Bookings",
              role: 'admin',
            },
            {
              link: "/company-billing-credit",
              title: "Company Billing Credit",
              role: 'admin',
            },
          ],
        },
        {
          title: "Finance",
          role: 'finance',
          links: [
            {
              link: "/finance-management",
              title: "Weekly Management",
              role: 'finance',
            },
            {
              link: "/finance-dashboard",
              title: "Finance Dashboard",
              role: 'finance',
            },
          ],
        },
        {
          title: "Carriers Management",
          role: 'admin',
          links: [
            {
              link: "/carriers/service-groups",
              title: "Service Groups",
              role: 'admin',
            },
            {
              link: "/carriers/carrier-zones",
              title: "Carrier Zones",
              role: 'admin',
            },
            {
              link: "/carriers/carrier-billing-rates",
              title: "Carrier Billing Rates",
              role: 'admin',
            },
            {
              link: "/carriers/carrier-billing-surcharges",
              title: "Carrier Billing Surcharges",
              role: 'admin',
            },
            {
              link: "/carriers/service-settings-and-tags",
              title: "Service Settings & Tags",
              role: 'admin',
            },
            {
              link: "/carriers/available-carrier-services",
              title: "View available services",
              role: 'admin',
            },
            {
              link: "/carriers/format-carrier-rate-files",
              title: "Format Carrier Rate Files",
              role: 'admin',
            },
          ],
        },
      ],
    };
  },
  name: "home",
  components: {
    // EventsList
  },
  created: () => {},
  computed:{
    // filter which pages to show based on user roles
    filteredLinksArray(){
      let filtered = [] 
      for(let section of this.linksArray){
        if(!this.pageAccess(section.role)) continue
        let filteredLinks = []
        for(let link of section.links){
          if(!this.pageAccess(link.role)) continue
          filteredLinks.push(link)
        }
        section.links = filteredLinks 
        filtered.push(section)
      }
      return filtered
    },
  
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    pageAccess(role) {
      switch(role){
        case 'admin':
          return this.adminUser
        case 'dev': 
          return this.devUser
        case 'finance':
          return this.financeUser
        case 'viewOnly':
          return this.viewOnlyUser || this.adminUser
        default:
          return false
      }
      
    },
   
  },
};
</script>
<style lang="scss" scoped>
.homeLink {
  padding: 0.5em;
  :hover {
    background-color: #404040;
    transition: all 250ms linear;
    p {
      padding-left: 0;
      background-color: transparent;
      color: white;
    }
  }
}
.center {
  width: 100%;
  text-align: center;
}
.v-card p {
  padding-left: 0;
}
</style>
