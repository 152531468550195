<template>
  <div id="app">
    <v-app id="inspire">
      <Nav />
      <router-view/>
    </v-app>
  </div>
</template>
<script>
import Nav from './components/partials/Nav.vue';
export default {
  name: 'app',
  components: {
    Nav
  }
}
</script>
<style lang="scss">
.hero.is-primary {
    background-color: #404040 !important;
    color: #fff;
}
body .v-application {
  font-family: 'Nunito Sans', sans-serif !important;
}
.v-application--wrap {
  background-color: #e4e4e4;
}
.v-data-table td,  .v-data-table th {
  padding: 16px !important;
}
.hero-body {
  padding: 0rem 1.5rem !important;
}
.button-end {
  align-self: flex-end;
}
body .v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
}
.v-dialog--active .v-text-field__details {
  display: none !important;
}
.v-input .v-text-field__details {
  display: none;
}
</style>