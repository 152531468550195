import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from "../auth/authGuard";
import { authGuardAdmin } from "../auth/authGuardAdmin";
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/adhoc-charges',
      name: 'adhocCharges',
      component: () => import('../views/AdhocCharges/AdhocCharges.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/search-adhoc-charges',
      name: 'searchAdhocCharges',
      component: () => import('../views/AdhocCharges/SearchAdhocCharges.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/billing-snap',
      name: 'billing-snap',
      component: () => import('../views/BillingSnap.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/billing-upload',
      name: 'billingUpload',
      component: () => import('../views/BillingUpload.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/carriers',
      name: 'carriers',
      component: () => import('../views/Carriers.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/carrier-margins-reconciliation',
      name: 'carrierMarginsReconciliation',
      component: () => import('../views/CarrierMarginsReconciliation.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/create-invoice',
      name: 'createInvoice',
      component: () => import('../views/CreateInvoice.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/create-invoice-prep-companies',
      name: 'createInvoicePrepCompanies',
      component: () => import('../views/CreateInvoicePrepCompanies.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/download-invoice-prep-companies',
      name: 'downloadInvoicePrepCompanies',
      component: () => import('../views/DownloadInvoicePrepCompanies.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/container-charges',
      name: 'containerCharges',
      component: () => import('../views/ContainerCharges.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/download-invoice',
      name: 'downloadInvoice',
      component: () => import('../views/DownloadInvoice.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/margins',
      name: 'margins',
      component: () => import('../views/Margins.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/recurring-charges',
      name: 'recurringCharges',
      component: () => import('../views/RecurringCharges.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('../views/Services.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/sorted-duplicates',
      name: 'sortedDuplicates',
      component: () => import('../views/SortedDuplicates.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/edit-sorted-data',
      name: 'editSortedData',
      component: () => import('../views/EditSortedData.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/carriers/invoice-reconciliation',
      name: 'Invoice Reconciliation',
      component: () => import('../views/carriers/InvoiceReconciliation.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/edit-snap-billing-rates',
      name: 'EditSnapBillingRates',
      component: () => import('../views/BillingRates/EditSnapBillingRates.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/inbound-bookings',
      name: 'InboundBookings',
      component: () => import('../views/InboundBookings.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/add-edit-snap-rates',
      name: 'AddEditSnapRates',
      component: () => import('../views/AddEditSnapRates.vue'),
      beforeEnter: authGuardAdmin
    },

    {
      path: '/finance-management',
      name: 'financeManagement',
      component: () => import('../views/FinanceManagement.vue'),
      beforeEnter: authGuardAdmin
    },
    {
      path: '/finance-Dashboard',
      name: 'financeDashboard',
      component: () => import('../views/FinanceDashboard.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/generated-billing-query',
      name: 'generatedBillingQuery',
      component: () => import('../views/GeneratedBillingQuery.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/sorted-billing-import-errors',
      name: 'sortedBillingImportErrors',
      component: () => import('../views/SortedBillingImportErrors.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/company-billing-credit',
      name: 'companyBillingCredits',
      component: () => import('../views/CompanyBillingCredit.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/delete-uncommitted-billing-data',
      name: 'deleteUncommittedBillingData',
      component: () => import('../views/DeleteUncommittedBillingData.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/billing-charges-by-order-number',
      name: 'BillingChargesByOrderNumber',
      component: () => import('../views/BillingChargesByOrderNumber.vue'),
      beforeEnter: authGuard
    },

    // carriers section
    {
      path: '/carriers/service-groups',
      name: 'carrierServiceGroups',
      component: () => import('../views/carriers/ServiceGroups.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/carriers/carrier-zones',
      name: 'CarrierZones',
      component: () => import('../views/carriers/CarrierZones.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/carriers/carrier-billing-rates',
      name: 'CarrierBillingRates',
      component: () => import('../views/carriers/CarrierBillingRates.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/carriers/carrier-billing-surcharges',
      name: 'CarrierBillingSurcharges',
      component: () => import('../views/carriers/CarrierBillingSurcharges.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/carriers/service-settings-and-tags',
      name: 'ServiceSettingsAndTags',
      component: () => import('../views/carriers/ServiceSettingsAndTags.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/carriers/available-carrier-services',
      name: 'AvailableCarrierServices',
      component: () => import('../views/carriers/AvailableCarrierServices.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/carriers/format-carrier-rate-files',
      name: 'FormatCarrierRateFiles',
      component: () => import('../views/carriers/FormatCarrierRateFiles.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/billing-rates/bulk-update-company-rates',
      name: 'BulkUpdateCompanyRates',
      component: () => import('../views/BillingRates/BulkUpdateCompanyRates.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/invoicing/xero-invoice-emails',
      name: 'XeroInvoiceEmails',
      component: () => import('../views/Invoicing/XeroInvoiceEmails.vue'),
      beforeEnter: authGuard
    },
    {
      path: '/invoicing/company-billing-settings',
      name: 'Company Billing Settings',
      component: () => import('../views/Invoicing/CompanyBillingSettings.vue'),
      beforeEnter: authGuard
    },


  ]
})