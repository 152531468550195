<template>
<header>
  <nav class="navbar container" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <img src="/assets/img/3pl-logo.svg" alt="3PL Logo" class="logo mr-2">
      <strong class="is-size-5 web-title mr-6">| Billing Console</strong>
      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="true" data-target="navbarBasicExample" @click="isActive = !isActive">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar" class="navbar-menu" v-bind:class="[!isActive ? '' : 'is-active']">
      <div v-if="$auth.isAuthenticated" class="navbar-start">
        <router-link to="/" class="navbar-item">Home</router-link>
        <div class="navbar-item has-dropdown is-hoverable" v-if="adminUser($auth.user.name)">
          <a class="navbar-link">
            Billing Information
          </a>
          <div class="navbar-dropdown">
            <router-link to="/adhoc-charges" class="navbar-item">Ad-Hoc Charges</router-link>
            <router-link to="/recurring-charges" class="navbar-item">Recurring Charges</router-link>
            <router-link to="/billing-upload" class="navbar-item">Upload Sorted Data</router-link>
            <router-link to="/billing-snap" class="navbar-item">View Snap Data</router-link>
            <router-link to="/sorted-duplicates" class="navbar-item">View Sorted Duplicates</router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable" v-if="adminUser($auth.user.name)">
          <a class="navbar-link">
            Carriers and Services
          </a>
          <div class="navbar-dropdown">
            <router-link to="/carrier-margins-reconciliation" class="navbar-item">Carrier Margins Reconciliation</router-link>
            <router-link to="/carriers" class="navbar-item">Carriers</router-link>
            <router-link to="/margins" class="navbar-item">Company Margins</router-link>
            <router-link to="/services" class="navbar-item">Services</router-link>
          </div>
        </div>
        <div class="navbar-item has-dropdown is-hoverable" v-if="adminUser($auth.user.name)">
          <a class="navbar-link">
            Invoicing
          </a>
          <div class="navbar-dropdown">
            <router-link to="/create-invoice" class="navbar-item">Submit Invoice Data</router-link>
            <router-link to="/download-invoice" class="navbar-item">Commit Invoice Data</router-link>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <!-- Check that the SDK client is not currently loading before accessing is methods -->
            <div v-if="!$auth.loading">
              <!-- show login when not authenticated -->
              <a v-if="!$auth.isAuthenticated" @click="login" class="button is-dark"><strong>Sign in</strong></a>
              <!-- show logout when authenticated -->
              <a v-if="$auth.isAuthenticated" @click="logout" class="button is-dark"><strong>Log out</strong></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="version">Version: {{ this.$root.sharedData.state.packageVersion }}</p>
  </nav>
</header>
</template>
<script>
export default {
  data() {
    return  { 
      isActive: false 
    }
  },
  name: 'Nav',
  methods: {
  // Log the user in
  login() {
    this.$auth.loginWithRedirect();
  },
  // Log the user out
  logout() {
    this.$auth.logout({
      returnTo: window.location.origin
    });
  },
  adminUser(user){
    if(user === 'ianwalker@3p-logistics.co.uk' || user === 'david.holland@myfdadvisor.co.uk' ){
      return false
    }else{
      return true
    }
  }
  }
}
</script>
<style lang="scss" scoped>
  nav {
    margin-top: 5px;
    margin-bottom: 10px;
    a {
      font-weight: bold;
      color: #404040;
      &.router-link-exact-active {
        color: #3f51b5;
      }
    }
  }
  .logo {
    width: 40px;
  }
  header {
    background-color: white;
  }
  .web-title {
    align-self: center;
  }
  .version {
    margin: auto;
  }
</style>